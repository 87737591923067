<template>
    <div class="page-head">
        <el-row>
            <el-col :span="24">
                <div>
                    <img src="../../public/img/icons/yetou.jpg" :style="{width: '100%', height: '30px'}"/>
                </div>
            </el-col>
        </el-row>
        <el-row :span="20">
            <el-col :span="8">
                <div class="qr-img">
                    <img :src=qrImg :style="{width: '100%', height: '100%'}"/>
                </div>
                <div class="qr-code">咨询联系微信</div>
            </el-col>
            <el-col :span="16">
                <el-carousel  height="145px" motion-blur 
                    ref="carousel"
                >
                    <el-carousel-item v-for="item in videos" :key="item">
                        <video 
                            class="video-carousel" 
                            :src="item" 
                            controls 
                            autoplay 
                            @ended="onVideoEnded()"
                        ></video>
                    </el-carousel-item>
                </el-carousel>
            </el-col>
        </el-row>
        <el-row>
            <!-- <el-col :span="6" v-for="(item, index) in items" :key="index">
                <div
                    class="grid-content"
                    :class="{ 'selected': selectedItem === index }"
                    @click="selectItem(index)"
                >{{ item }}</div>
            </el-col> -->
            <el-col :span="6">
                <div class="grid-content">
                    首页
                </div>
            </el-col>
            <el-col :span="6">
                <div class="grid-content-1">
                    直播答疑
                </div>
            </el-col>
            <el-col :span="6">
                <div class="grid-content">
                    视频介绍
                </div>
            </el-col>
            <el-col :span="6">
                <div class="grid-content-1">
                    地址账户
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script setup>
import axios from 'axios';
import { onMounted, ref } from 'vue';
import { useRouter , useRoute } from 'vue-router';


// const items = ref(['首页', '直播答疑', '视频介绍','地址账户']);
const selectedItem = ref(0);
const router = useRouter();
const route = useRoute();

const selectItem = (index) => {
    selectedItem.value = index;
    console.log(index);
    if(index === 0){
        console.log('首页');
    }else if(index === 1){
        console.log('直播答疑');
        // router.push("/videoTest")
        
    }else if(index === 2){
        console.log('视频介绍');
    }
}
const qrImg = ref('');

const videos = ref([
        // '/api/file/video?fileName=34fc1a22-df2c-43cc-9305-e04b46307dc4.mp4',
]);

const getQrImg = async () => {
    const res = await axios.get('/api/qrCode/getHomeCode');
    // console.log(res);
    qrImg.value = res.data.data.qrCodeUrl;
    // console.log(qrImg.value);
 }

const getVideos = async () => {
    const res = await axios.get('/api/videoResource/getHomeVideos');
    // 后续改进如果视频列表为空的话，设置默认播放视频路径，在此方法中设置。
    videos.value = res.data.data;
}


onMounted(() => {
    getQrImg();
    getVideos();
})

const carousel = ref(null); // 获取轮播图组件实例

// 监听视频播放完毕
const onVideoEnded = () => {
    console.log('视频播放完毕');
    carousel.value.next();

}


</script>


<style>
.page-head{
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
}

.grid-content {
    cursor: pointer; 
    transition: background-color 0.3s ease, color 0.3s ease, border 0.3s ease; /* 平滑过渡 */
    text-align: center; /* 文字居中 */
    border: 2px solid transparent; /* 边框默认透明 */
    background-color: #0c76b2;
    color: #ffffff;
    /* width: 60%; */
}
.grid-content-1 {
    cursor: pointer; 
    transition: background-color 0.3s ease, color 0.3s ease, border 0.3s ease; /* 平滑过渡 */
    text-align: center; /* 文字居中 */
    border: 2px solid transparent; /* 边框默认透明 */
    background-color: #3db155;
    color: #ffffff;
}



.demonstration {
  color: var(--el-text-color-secondary);
}

.el-carousel__item h3 {
  color: #475669;
  opacity: 0.75;
  line-height: 54px;
  margin: 0;
  text-align: center;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

.qr-img{
    background-size: cover;
    padding: 10px;
}
.video-carousel{
    width: 90%;
    height: 100%;
}
.el-carousel__item:nth-child(n){
    background-color: #ffffff;
}
.qr-code{
    font-size: 12px;
}
</style>